.thumbnail-selector {
    border: 1px dashed #6c757d; /* border-secondary */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 0.875rem; /* text-sm */
    aspect-ratio: 16 / 9; /* aspect-video */
    width: 15rem; /* w-32 */
  }
  
  .thumbnail-selector img.object-cover {
    object-fit: cover;
   

  }
  
  .thumbnail-selector-wrapper {
    width: 15rem; /* w-32 */
    font-size: 0.875rem; /* text-sm */
    
  }
  