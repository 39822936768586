.btn {
    padding: 0.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .bg-secondary {
    background-color: #6c757d !important;
  }
  
  .flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .gap-x-4 {
    gap: 1rem;
  }
  
  .space-x-2 {
    gap: 0.5rem;
  }
  
  .border-x-2 {
    border-left-width: 2px;
    border-right-width: 2px;
  }
  
  .border-primary {
    border-color: #007bff;
  }
  