/*-----------------------
    Blog Style  
-------------------------*/

.rbt-blog-grid {
    &.rbt-card {
        .rbt-card-img {

        }
        .rbt-card-body {
            .rbt-card-title {
                margin-bottom: 13px;
            }
            .blog-meta {
                margin-bottom: 10px;
            }
        }
    }
}





















