/*------------------------
    Shop Styles  
--------------------------*/
.filter-select {
    select {
        border: 0 none;
        box-shadow: var(--shadow-1);
        width: auto;
        min-width: 250px;
    }
}


















