/*********************************************************************************

	Template Name: Histudy - Online Courses & Education NEXTJS14 Template
	Description: A perfect Template For Education. It comes with nice and clean design.

	Note: This is style css.

**********************************************************************************/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|	|___ edu-common
	|	|___ forms
	|	|___ text-animation
	|
	|
	|___Header Styles
	|	|___ header-common
	|	|___ headertop
	|	|___ offertopbar
	|	|___ headermid
	|	|___ social-share
	|	|___ header
	|	|___ nav
	|	|___ mega-menu
	|	|___ mobilemenu
	|	|___ offcanvas
	|	|___ one-page-navigation
	|
	|
	|___Elements Styles
	|	|___ button 
	|	|___ section-title
	|	|___ breadcrumb
	|	|___ card
	|	|___ course-meta
	|	|___ service
	|	|___ feature
	|	|___ about
	|	|___ callto-action
	|	|___ accordion
	|	|___ image-gallery
	|	|___ counterup
	|	|___ pricingtable
	|	|___ badge
	|	|___ team
	|	|___ social
	|	|___ pagination
	|	|___ backtotop
	|	|___ newsletterform
	|	|___ brand
	|	|___ portfolio
	|	|___ contact
	|	|___ testimonial
	|	|___ advance-tab
	|	|___ category
	|	|___ instagram
	|	|___ split
	|	|___ countdown
	|	|___ progressbar
	|	|___ category-box
	|	|___ course-filter
	|	|___ modal
	|	|___ list
	|	|___ search
	|	|___ video
	|	|___ 404

	|___Blog Styles
	|	|___ blog 
	|	|___ post-default 
	|	|___ sidebar 
	|	|___ blog-details 
	|	|___ unit-test 

	|___Shop Styles
	|	|___ shop 
	|	|___ product-details 
	|	|___ my-account 
	|	|___ cart 
	|	|___ checkout 
	|	|___ minicart 

	|___Template Styles
	|	|___ banner 
	|	|___ course-details 
	|	|___ course-sidebar 
	|	|___ contact 
	|	|___ sidebar 
	|	|___ course-action-bottom 


	|___Footer Styles
	|	|___ footer 
	|	|___ copyright 
	|	|___ back-to-top 
	|	|___ dark 
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/

/**************************************
    Default Styles
***************************************/
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';
@import 'default/edu-common';
@import 'default/forms';
@import 'default/text-animation';

/**************************************
	Header Styles
***************************************/

@import 'header/common/header-common';
@import 'header/common/headertop';
@import 'header/topoffer/offertopbar';
@import 'header/headermiddle/headermid';
@import 'header/social-share';
@import 'header/header';
@import 'header/nav';
@import 'header/mega-menu';
@import 'header/mobilemenu';
@import 'header/offcanvas';
@import 'header/one-page-navigation';

/**************************************
	Elements Styles
***************************************/
@import 'elements/button';
@import 'elements/section-title';
@import 'elements/breadcrumb';
@import 'elements/card';
@import 'elements/course-meta';
@import 'elements/service';
@import 'elements/swiper';
@import 'elements/feature';
@import 'elements/about';
@import 'elements/callto-action';
@import 'elements/accordion';
@import 'elements/image-gallery';
@import 'elements/counterup';
@import 'elements/pricingtable';
@import 'elements/badge';
@import 'elements/team';
@import 'elements/social';
@import 'elements/pagination';
@import 'elements/backtotop';
@import 'elements/newsletterform';
@import 'elements/brand';
@import 'elements/portfolio';
@import 'elements/contact';
@import 'elements/testimonial';
@import 'elements/advance-tab';
@import 'elements/category';
@import 'elements/instagram';
@import 'elements/split';
@import 'elements/countdown';
@import 'elements/progressbar';
@import 'elements/category-box';
@import 'elements/course-filter';
@import 'elements/modal';
@import 'elements/list';
@import 'elements/search';
@import 'elements/video';
@import 'elements/switcher';
@import 'elements/404';

/**************************************
	Blog Styles
***************************************/
@import 'blog/blog';
@import 'blog/post-default';
@import 'blog/sidebar';
@import 'blog/blog-details';
@import 'blog/unit-test';

/**************************************
	Shop Styles
***************************************/

@import 'shop/shop';
@import 'shop/product-details';
@import 'shop/my-account';
@import 'shop/cart';
@import 'shop/checkout';
@import 'shop/minicart';

/**************************************
	Template Styles
***************************************/
@import 'template/banner';
@import 'template/course-details';
@import 'template/course-sidebar';
@import 'template/contact';
@import 'template/sidebar';
@import 'template/course-action-bottom';
@import 'template/instructor-dashboard';
@import 'template/lesson';

/**************************************
	Footer Styles
***************************************/
@import 'footer/footer';
@import 'footer/copyright';
@import 'footer/back-to-top';
@import 'footer/dark';
@import 'template/preview';
@import 'template/preview';

/**************************************
	Dark Mode
***************************************/
@import 'dark-mode/dark-mode';

/************************************
   Sonradan Eklenenler (Global)
*************************************/

@import 'editor/thumbnail-selector';
@import 'editor/editor';
@import 'editor/toolbar';

@import 'admin/components/modal';

//! sonradan eklendi
.clamp-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-6-lines {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-8-lines {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.loadingContainer {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: opacity 0.5s ease-out;

  > img {
    width: 350px;
    object-fit: contain;
    @media (max-width: 768px) {
      width: 250px;
    }
  }

  &.hidden {
    opacity: 0;
  }

  &.visible {
    opacity: 1;
  }

  h2 {
    font-family: serif;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  p {
    font-family: serif;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}

/* whatsapp icon component start */
.floating-whatsapp-chatbox {
  bottom: 9rem !important;
}

.floating-whatsapp-chatbox > header > div:first-child {
  background-color: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-left: 4px;
}

.floating-whatsapp-chatbox .styles-module_statusTitle__NgfPQ {
  font-size: 16px !important;
}

.floating-whatsapp-chatbox .styles-module_statusSubtitle__esavW {
  font-size: 12px !important;
}

.floating-whatsapp-chatbox .styles-module_messageBody__9-hwo {
  margin-bottom: 0 !important;
}

.floating-whatsapp-chatbox > footer button {
  display: flex;
  justify-content: flex-end;
}

.floating-whatsapp-chatbox > header > div:first-child::after {
  bottom: 0;
}

.floating-whatsapp-chatbox > header img {
  object-fit: contain;
}

/* whatsapp icon component end */

// -----------------------------

/* back to top start */
.rbt-progress-parent {
  right: 100px !important;
}

/* Contact us form start */
.form-group input {
  padding-bottom: 0px;
  padding-top: 0px;
}
/* Contact us form end */

/* login page start */

.login-page {
  .social-login {
    .social-buttons {
      display: flex;
      flex-direction: column;
      row-gap: 0.6rem;

      .google {
        background-color: #dc3545;
      }

      .github {
        background-color: #212529;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 3px;

        .feather-github {
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

/* login page end */

/* StudentDashboardHeader.css Start */
/* StudentDashboardHeader.css */

.thumbnail {
  position: relative;
}

.thumbnail:hover .edit-button {
  opacity: 1;
}

.thumbnail .edit-button {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 100%;
  margin: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
}

/* StudentDashboardHeader.css End */

/* Blog Content Styles */
.blog-content {
  font-family: 'Georgia', serif;
  font-size: 18px;
  line-height: 1.8;
  color: #333;
  margin: 20px 0;
  color: white;
}

.blog-content h1,
.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6 {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.2;
  color: white;
}

.blog-content p {
  margin-bottom: 1em;
}

.blog-content a {
  color: #007bff;
  text-decoration: none;
}

.blog-content a:hover {
  text-decoration: underline;
}

.blog-content ul,
.blog-content ol {
  margin: 1em 0;
  padding: 0 1.5em;
}

.blog-content blockquote {
  margin: 1.5em 0;
  padding: 0.5em 1.5em;
  border-left: 4px solid #ccc;
  background-color: #f9f9f9;
}

.blog-content img {
  max-width: 100%;
  height: auto;
  margin: 1.5em 0;
}

.blog-content code {
  background-color: #f5f5f5;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
}

/*! copy-button  */
.content .copy-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 4px 10px;
  border: none;
  // border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s;
}

.content .copy-button:hover {
  color: #800080;
}

@media (max-width: 768px) {
  .content .copy-button {
    display: none;
  }
}
/*! copy-button  */
