/* Blog Content Styles for Dark Mode */
.blog-content {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #e0e0e0; /* Açık gri metin rengi */
  background-color: #212529; /* Koyu arka plan rengi */
  margin-top: 1.5rem;
  padding: 1rem; /* İçerik etrafında boşluk */
  border-radius: 0.5rem; /* Köşe yuvarlama */
}

.blog-content h1, .blog-content h2, .blog-content h3, .blog-content h4, .blog-content h5, .blog-content h6 {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: #ffffff; /* Beyaz başlık rengi */
}

.blog-content p {
  margin-bottom: 1rem;
}

.blog-content a {
  color: #bb86fc; /* Açık mor link rengi */
  text-decoration: none;
}

.blog-content a:hover {
  text-decoration: underline;
}

.blog-content ul, .blog-content ol {
  margin: 1rem 0;
  padding: 0 1.5rem;
  color: #e0e0e0; /* Açık gri liste metni */
}

.blog-content blockquote {
  margin: 1.5rem 0;
  padding: 0.75rem 1.25rem;
  border-left: 0.25rem solid #bb86fc; /* Açık mor sınır rengi */
  background-color: #1f1f1f; /* Koyu gri arka plan */
  color: #e0e0e0; /* Açık gri metin rengi */
}

.blog-content img {
  max-width: 100%;
  height: auto;
  margin: 1.5rem 0;
  border: 1px solid #333; /* Koyu gri sınır */
  border-radius: 0.5rem; /* Köşe yuvarlama */
}

.blog-content pre {
  background-color: #333; /* Daha koyu arka plan */
  color: #e0e0e0; /* Açık gri metin rengi */
  padding: 1rem;
  border-radius: 0.5rem; /* Köşe yuvarlama */
  overflow-x: auto;
}

.blog-content code {
  background-color: #1e1e1e !important; /* Daha koyu arka plan */
  padding: 0.2rem 0.4rem;
  border-radius: 0.25rem;
  font-family: 'Courier New', Courier, monospace;
  color: #ff9191; /* Açık turuncu metin rengi */
}


